import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import CristalLogoLight from "../images/logo-cristalhub-light.svg";
import CristalLogoDark from "../images/logo-cristalhub-dark.svg";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {CristalLogo} from "./Parts/AlveoleConstants";

const Layout = ({children}) => {
    const [bLoading, setBLoading] = React.useState(false);
    const theme = useTheme();

    const handleCloseLoading = () => {
        setBLoading(false);
    }

    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl">
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={bLoading}
                    onClick={handleCloseLoading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>

                <Box>
                    <Grid container spacing={5} justifyContent="center"
                          alignItems="center">
                        <Grid item sx={{margin: '72px 0 72px 0'}}>
                            <CristalLogo
                                src={theme.palette.mode === 'light' ? `${CristalLogoLight}` : `${CristalLogoDark}`}
                                loading="lazy"
                                sx={{maxWidth: '100%'}}/>
                        </Grid>
                    </Grid>
                    {/*  Contenu spécifique de chaque composant */}
                    {React.Children.map(children, child =>
                        React.cloneElement(child)
                    )}
                </Box>
            </Container>
        </>
    )
}

export default Layout;
